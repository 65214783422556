import { generateTimeSlots } from './TimetableHandlers';
import kas from '../../assets/images/kas.jpeg';
import veld from '../../assets/images/veld.jpeg';
import terras from '../../assets/images/terras.jpeg';
import camping from '../../assets/images/camping.jpeg';
import photobooth from '../../assets/images/photobooth.jpeg';
import stal from '../../assets/images/stal.jpeg';
import ontbijt from '../../assets/images/ontbijt.jpeg';

export const timeSlotsFriday = generateTimeSlots('1500', '0045');
export const timeSlotsFridayEvening = generateTimeSlots('2000', '0045');

export const eventLayoutFriday = [
	{
		venue: 'Het Veld',
		events: [
			{ event: 'Inloop', startTime: '1515', endTime: '1600' },
			{ event: 'Toast', startTime: '1630', endTime: '1700' },
			{ event: 'Borrel', startTime: '1700', endTime: '1830' },
		],
		image: veld,
	},
	{
		venue: 'De Kas',
		events: [
			{ event: 'Inloop', startTime: '1530', endTime: '1600' },
			{ event: 'Ceremonie', startTime: '1600', endTime: '1630' },
		],
		image: kas,
	},
	{
		venue: 'Het Terras',
		events: [
			{ event: 'Diner', startTime: '1830', endTime: '2030' },
			{ event: 'Patat!', startTime: '2315', endTime: '2345' },
		],
		image: terras,
	},
	{
		venue: 'De Stal',
		events: [
			{ event: 'DJ & Welkom', startTime: '2030', endTime: '2100' },
			{ event: 'Band', startTime: '2100', endTime: '2145' },
			{ event: 'DJ', startTime: '2145', endTime: '2230' },
			{ event: 'Band', startTime: '2230', endTime: '2315' },
			{ event: 'DJ', startTime: '2315', endTime: '2345' },
			{ event: 'Band', startTime: '2345', endTime: '0030' },
		],
		image: stal,
	},
	{
		venue: 'Photobooth',
		events: [
			{ event: 'Leg deze onvergetelijke dag vast bij onze photobooth!', startTime: '2030', endTime: '0030' }
		],
		image: photobooth,
	},
];

export const eventLayoutFridayEvening = [
	{
		venue: 'De Stal',
		events: [
			{ event: 'DJ & Welkom', startTime: '2030', endTime: '2100' },
			{ event: 'Band', startTime: '2100', endTime: '2145' },
			{ event: 'DJ', startTime: '2145', endTime: '2230' },
			{ event: 'Band', startTime: '2230', endTime: '2315' },
			{ event: 'DJ', startTime: '2315', endTime: '2345' },
			{ event: 'Band', startTime: '2345', endTime: '0030' },
		],
		image: stal,
	},
	{
		venue: 'Photobooth',
		events: [
			{ event: 'Leg deze onvergetelijke avond vast bij onze photobooth!', startTime: '2030', endTime: '0030' }
		],
		image: photobooth,
	},
	{
		venue: 'Het Terras',
		events: [
			// { event: 'Lekker luchtje scheppen', startTime: '2130', endTime: '2230' },
			{ event: 'Patat!', startTime: '2315', endTime: '2345' },
		],
		image: terras,
	}
];

export const timeSlotsSaturday = generateTimeSlots('0800', '1145');

export const eventLayoutSaturday = [
	{
		venue: 'Camping 1',
		events: [
			{ event: 'Uitslapen', startTime: '0800', endTime: '1000' },
			{ event: 'Tentjes opruimen', startTime: '1100', endTime: '1145' },
		],
		image: camping,
	},
	{
		venue: 'Het Terras',
		events: [{ event: 'Ontbijt', startTime: '1000', endTime: '1100' }],
		image: ontbijt,
	},
];

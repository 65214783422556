import { Fragment, useState } from 'react';
import { RSVPForm } from '../components/RSVP/RSVPForm';
import { Button } from '../components/Button/Button';
import { useGuestType } from './Layout';

export interface FormData {
	name?: string;
	email?: string;
	presence?: string;
	sleep?: string;
	breakfast?: string;
	diet?: string;
	requestsOverall?: string;
}


function RSVP() {
    const { guestType } = useGuestType();

	const [submitted, setSubmitted] = useState(false);
	const [isCamping, setIsCamping] = useState(false);
	const [isComing, setIsComing] = useState(false);
	const [formData, setFormData] = useState<FormData>({});

	const handleResetForm = () => {
		setSubmitted(false)
		setFormData({})
	}

	const componentText = () => {
		if(submitted) {
			return (
				<>
					<h3> We hebben je RSVP ontvangen!</h3>
						{isComing && isCamping && (
							<Fragment>
								<span>Je hebt aangegeven graag te willen blijven kamperen. Reserveer je plekje op de camping via onderstaande button. Vergeet ook niet het formulier nog eens in te vullen als je met z'n tweeën komt. Iedere persoon moet zich apart aanmelden. </span><br/><br/>
								<Button buttonType='primary' buttonUrl='/#/tickets'>Camping tickets</Button>
								<Button buttonType="secondary" onClick={handleResetForm} className='btn'>Vul RSVP nog eens in</Button>

							</Fragment>
						)}
						{isComing && !isCamping && (
							<Fragment>
								<p>Vergeet niet het formulier nog eens in te vullen als je met z'n tweeën komt. Iedere persoon moet zich apart aanmelden. </p>
								<Button buttonType="primary" onClick={handleResetForm} className='btn'>Vul nog eens in</Button>
							</Fragment>
						)}
						{!isComing && <span>Jammer dat er er niet bij kunt zijn. </span>}				
				</>
			)
		} else {
			return (
				<Fragment>
					<p>We hebben erg veel zin om onze 
						{guestType === 'dag' ? ( <span> speciale dag </span> ) : ( <span> feestlijke avond </span> )}
						met jou te vieren! Vul het onderstaande formulier in, zodat wij de bruiloft tot in detail kunnen voorbereiden. Ook als je niet kan komen is het fijn als je dit aangeeft. De deadline voor het invullen is dinsdag 30 april.
						<br/><br/>
						<strong> Let op: </strong> je vult de RSVP per persoon in. Kom je samen? Vul dan beiden het RSVP formulier in.
					</p>
				</Fragment>
			)
		}
	}

	return (
		<>
			<h2>{submitted ? 'Bedankt' : 'RSVP'}</h2>
			{componentText()}
			<RSVPForm 
				submitted={submitted} 
				setSubmitted={setSubmitted} 
				isCamping={isCamping}
				setIsCamping={setIsCamping}
				isComing={isComing}
				setIsComing={setIsComing}
				formData={formData}
				setFormData={setFormData}
			/>
		</>
	);
}

export default RSVP;

import { useGuestType } from './Layout';
import samen from '../assets/images/samen.jpg';
import { useEffect, useState } from 'react';
import ImageSlider from '../components/ImageSlider/ImageSlider';
import { Button } from '../components/Button/Button';

import kas from '../assets/images/kas.jpeg';
import champagne from '../assets/images/champagne.jpeg';
import terras from '../assets/images/terras.jpeg';


function Home() {
	const { guestType } = useGuestType();

	const currentDate = new Date();
	const targetDate = new Date(currentDate.getFullYear(), 5, 7); // June is month 5 (0-indexed)
	
	const differenceInTime = targetDate.getTime() - currentDate.getTime();
	const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));

	const [daysToGo, setDaysToGo] = useState(differenceInDays + 25);
	const [speedOfCountdown, setSpeedOfCountdown] = useState(1);

	const images = [kas, champagne, terras]

	useEffect(() => {
		const intervalId = setInterval(() => {
		  if (daysToGo > differenceInDays) {
			setDaysToGo(prev => prev - 1);
			setSpeedOfCountdown(prev => prev + 5)
		  } else {
			clearInterval(intervalId);
		  }
		}, speedOfCountdown); 
	
		return () => clearInterval(intervalId);
	  }, [daysToGo, speedOfCountdown, differenceInDays]);

	return (
		<div className="home">
			<div className='home-wrapper'>
				<div className="text-wrapper">
					<h1>You are <br/>invited</h1>
					<div className="date">
						<h2>
							<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" zoomAndPan="magnify" viewBox="0 0 224.87999 198.749996" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="c5de077f81"><path d="M 0.0820312 0 L 224.679688 0 L 224.679688 198.5 L 0.0820312 198.5 Z M 0.0820312 0 " clipRule="nonzero"/></clipPath></defs><g clipPath="url(#c5de077f81)"><path d="M 96.1875 -0.0117188 L 92.085938 3.269531 L 109.3125 29.667969 L 0.804688 198.808594 L 81.496094 198.808594 L 81.496094 142.429688 L 143.839844 142.429688 L 143.839844 198.808594 L 224.535156 198.808594 L 116.023438 29.59375 L 132.28125 3.566406 L 128.253906 0.285156 L 112.667969 25.492188 L 96.1875 -0.015625 Z M 96.1875 -0.0117188 " fillOpacity="1" fillRule="evenodd"/></g></svg>
							07.06.24
						</h2>
						<h3>
							Eigen Wijze, Bant
						</h3>
					</div>
				</div>
				<ImageSlider images={images}/>
				<h3 className='countdown'>Only <strong className='days'>{daysToGo}</strong> days to go!</h3>
			</div>
			<div className="intro-wrapper">
				<div className="text-wrapper">
					{/* <h4>Een boventitel is soms leuk?</h4> */}
					<h2>Welkom!</h2>
					<p>Wij, Frank en Hannet, kijken er ontzettend naar uit om jullie te mogen verwelkomen op 
						
						{guestType === 'dag' ? ' onze bruiloft ' : ' ons bruiloftsfeest ' }
						
					op 7 juni 2024 bij vakantiepark Eigen Wijze in Bant. 
					<br/><br/>
					Het belooft een fantastische 
					
						{guestType === 'dag' ? ' dag ' : ' avond ' } 
					
					te worden vol geluk, liefde en gezelligheid.  
					
						{guestType === 'dag' ? ' Er wordt gezorgd voor heerlijk eten en drinken' : ' Voor (meer dan) voldoende drankjes en lekkere hapjes wordt gezorgd' } 
					
					, en natuurlijk kan er 
					
						{guestType === 'dag' ? ' in de latere uurtjes ook volop ' : ' tot in de late uurtjes ' }
					
					gedanst worden op de muziek van de DJ en de band.
					<br/><br/>
					We hopen dat jullie er net zo naar uitkijken als wij en dat we er samen een onvergetelijke 

						{guestType === 'dag' ? ' dag  ' : ' avond ' }

					van gaan maken. Mocht je nog vragen hebben, aarzel dan niet om contact met ons of onze ceremoniemeesters op te nemen. Tot de 7e!</p>
					<Button buttonType="primary" buttonUrl='/#/rsvp'>RSVP</Button>
				</div>
				<div className="image-wrapper">
					<div className="img-container"> <img src={samen} alt="" /></div>
				</div>
			</div>

		</div>
	);
}

export default Home;

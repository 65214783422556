import { ReactNode } from 'react';

interface RSVPLabelProps {
	title: string;
	id: string;
	children: ReactNode;
}

export const RSVPLabel = ({ title, children, id }: RSVPLabelProps) => {
	return (
		<div className='form__label-wrapper'>
			<label className='form__label' htmlFor={id}>
				{title}
			</label>
			{children}
		</div>
	);
};

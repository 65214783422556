import ImageSlider from '../components/ImageSlider/ImageSlider';
import ccamping from '../assets/images/ccamping.jpg';
import ccamping1 from '../assets/images/ccamping5.jpg'
import ccamping2 from '../assets/images/ccamping2.jpg';
import ccamping3 from '../assets/images/ccamping3.jpg';
import { Button } from '../components/Button/Button';

const Tickets = () => {
	const images = [ccamping, ccamping2, ccamping1, ccamping3]

	return (
		<>
			<h2>Camping Tickets</h2>
            <div className="camping-text">
                <p>Wat leuk dat je wilt blijven kamperen! Reserveer je plekje op de camping door één van de twee tikkies hieronder te betalen. Je tikkie is je ticket!</p> <br/>
                <p>P.S. Je hoeft geen bammetjes te smeren voor de volgende ochtend. Er staat zaterdagochtend een heerlijk festival ontbijtje voor je klaar *opent blikje knakworst*. </p>
                <div className="m-top m-bottom">
                    <Button buttonUrl="https://betaalverzoek.rabobank.nl/betaalverzoek/?id=m_N6EYWiRAmhehoy_ImXyA" buttonType='primary' targetBlank>Eén persoon</Button>
                    <Button buttonUrl="https://betaalverzoek.rabobank.nl/betaalverzoek/?id=Z_-IUpLZQ6Sd9colSRxtTQ" buttonType='primary' targetBlank>Twee personen</Button>
                </div>
            </div>
			<ImageSlider images={images} />
		</>
	);
}

export default Tickets;

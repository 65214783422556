import { FunctionComponent, ReactNode } from 'react';

interface FaqItemProps {
    children: ReactNode;
}

const FaqItem: FunctionComponent<FaqItemProps> = ({children}) => {
    return (
        <div className='faq-item'>
            {children}
        </div>
    )
}

export default FaqItem;
import clsx from 'clsx';
import React, { ReactNode } from "react";

interface Props {
	buttonType: "primary" | "secondary" | 'link' | 'primary-green';
    type?: "button" | "submit" | "reset" | undefined;
	buttonUrl?: string;
	onClick?: () => void;
    className?: string;
    children?: ReactNode;
    targetBlank?: boolean;
}

export const Button = ({ buttonType, buttonUrl, onClick, className, children, type, targetBlank }: Props) => {

    if(type) {
        return (
            <button type={type} className={clsx('button', className, buttonType) }>
                {children}
            </button>
        )

    }

	return (
		<a href={buttonUrl} className={clsx('button', className, buttonType) } onClick={onClick} target={targetBlank ? "_blank" : '_self'} rel="noreferrer">
			{children}
		</a>
	);
};

import camping from '../assets/images/overnachten.jpg';
import dresscode from '../assets/images/dresscode.jpeg';
import locatie from '../assets/images/locatie.jpeg';
import tijd from '../assets/images/tijd.jpeg';
import kas2 from '../assets/images/kas2.jpeg';
import FlipContainer from '../components/FlipContainer/FlipContainer';
import FaqItem from '../components/FaqItem/FaqItem';
import { useGuestType } from './Layout';
import { Button } from '../components/Button/Button';

const Info = () => {
    const { guestType } = useGuestType();

    return (
        <>
            <h2>Belangrijke Informatie</h2>
            <div className='info-wrapper'>
                <FlipContainer imgSrc={tijd} className='div2'>
                        <h3>Datum & Tijd</h3>
                        <p>Je bent van harte welkom om op <strong>7 juni 2024</strong> de liefde met ons te vieren. 

						{guestType === 'dag' ? (
							<span> De bar is geopend vanaf <strong>15:15</strong> uur en de ceremonie begint om <strong>16:00</strong> uur.</span>
						) : (
							<span> De bar is open vanaf <strong>20:30</strong> uur.</span>
							
						)}
						<br/><br/>
						Blijf je kamperen? Dan kun je tussen 
						{guestType === 'dag' ? (
							<span><strong> 10:00 en 15:00 </strong></span>
						) : (
							<span><strong> 10:00 en 14:30</strong> of na <strong>19:30 </strong></span>
							
						)}
						uur terecht op de camping om je tentje vast op te zetten. </p>
                    </FlipContainer>
                <FlipContainer imgSrc={locatie} className='div3'>
                    <h3>Locatie</h3>
                    <p>
                        De bruiloft vindt plaats bij <strong>Vakantiepark Eigen Wijze</strong> in Bant. 
						
						{guestType === 'dag' ? (
							<span> Alles is op één locatie en er is voldoende ruimte om te parkeren.</span>
						) : (
							<span> Er is voldoende ruimte om te parkeren.</span>
						)}
						
						<br/><br/>
                        Vakantiepark Eigen Wijze<br/>
                        Schoterpad 1<br/>
                        8314 RA Bant<br/>
						<a href="https://www.vakantieparkeigenwijze.nl/" target='blank'>www.vakantieparkeigenwijze.nl</a>
                    </p>
                </FlipContainer>
                <FlipContainer imgSrc={camping} className='div1'>
                    <h3>Overnachten</h3>
                    <p>We vinden het leuk als je blijft kamperen, maar dit is natuurlijk niet verplicht. Of je nu met een tent, camper of vouwwagen komt, er is genoeg ruimte beschikbaar voor iedereen. Vergeet alleen niet je eigen kampeerspullen mee te nemen. 
					<br/><br/>	
					Een plekje op de camping kost <strong>10 euro</strong> per persoon. Dit kun je betalen via onderstaande knop. Je tikkie is je ticket, dus vergeet deze niet te betalen. 
					<br/>
					<Button buttonType='primary' buttonUrl='/#/tickets'>Camping tickets</Button>
                    </p>
                </FlipContainer>
                <FlipContainer imgSrc={dresscode} className='div4'>
                    <h3>Dresscode</h3>
                    <p>Laat je innerlijke festivalganger stralen met het thema  <strong>Colorful Chique</strong>. Dit betekent: kleurrijk, funky, chique en klaar om te dansen. Pak gerust uit, maar onthoud: het is het allerbelangrijkst dat je prettig voelt!</p>
                </FlipContainer>
                <FlipContainer imgSrc={kas2} className='div5'>
                    <h3>Cadeautip</h3>
                    <p>Jullie aanwezigheid is voor ons natuurlijk al een fantastisch cadeau! Maar, als jullie iets willen geven, zouden wij een bijdrage voor onze huwelijksreis erg waarderen. &#9993;</p>
                </FlipContainer>
            </div>
            <h3>Overige vragen</h3>
            <div className="faq">
				<FaqItem>
					<h4>Hoe kan ik doorgeven dat ik erbij ben?</h4>
					<p>Bevestig je aanwezigheid via het <a href='/#/rsvp'>RSVP-formulier</a>. Ook als je niet aanwezig kunt zijn, is het fijn als je het formulier invult. Zo weten wij op tijd hoeveel mensen er aanwezig zijn op onze bruiloft. De deadline voor de RSVP is dinsdag 30 april. Zorg dus dat je je op tijd aanmeldt. </p>
				</FaqItem>
					{guestType === 'dag' && (
						<FaqItem>
							<h4>Hoe kan ik mijn dieetwensen doorgeven?</h4>
							<p>Dieetwensen en/of allergieën kan je doorgeven bij het invullen van het <a href='/#/rsvp'>RSVP-formulier</a>. Wij houden hier dan zeker rekening mee!</p>
						</FaqItem>
					)}	
				<FaqItem>
					<h4>Is er ruimte voor een stukje {guestType === 'dag'  ? ' of een speech?' : '?'} </h4>
					<p>
						{guestType === 'dag' 
							? <span>Hoewel we niet staan te springen om dia-shows van 25 minuten, is er zeker ruimte om iets leuks te doen. Dit is wel altijd in overleg met onze ceremoniemeesters.</span>
							: <span>Nee. We willen de feestavond écht een feest laten zijn, zonder onderbrekingen.</span>
						}
					</p>
				</FaqItem>
				<FaqItem>
					<h4>Ik wil graag blijven slapen, maar liever niet in een tent. Wat nu? </h4>
					<p> Wil je liever iets comfortabelers dan een plekje op de camping? Neem dan zelf contact op met de accommodatie en boek je plekje op het park bij de Glampcamp! </p>
				</FaqItem>
				<FaqItem>
					<h4>Heb je een andere vraag?</h4>
					<p>Neem dan contact op met onze lieve ceremoniemeesters Carlien en Pim, of stuur ons een berichtje. Meer informatie vind je op de <a href='/#/contact'>contact pagina</a></p>
				</FaqItem>
			</div>
        </>
    )
}

export default Info;
import { FunctionComponent } from "react";

interface ContactPersonProps {
	imageUrl: string;
    name: string;
    titel: string;
    number: string;
}

export const ContactPerson: FunctionComponent<ContactPersonProps> = ({imageUrl, name, titel, number}) => {

	return (
		<div className='contact-person'>
            <img src={imageUrl} alt={titel} />
            <h3>{name}</h3>
            <p>{titel}</p>
            <a href={`tel:${number}`}>{number}</a>
            <a href="mailto:info@frank-hannet.nl">info@frank-hannet.nl</a>
        </div>
	);
};

import { FunctionComponent } from 'react';
import { generateTimeslotCss } from './TimetableHandlers';
import TimetableEvent from './TimetableEvent';
import { v4 as uuidv4 } from 'uuid';

interface TimetableRowProps {
    location: any;
    timeSlots: string[];
}

const TimetableRow: FunctionComponent<TimetableRowProps> = ({location, timeSlots}) => {
    return (
        <div className='timetable-row'>
            <img
                className='timetable-image'
                alt='location'
                src={location.image}
            />
            <div className='timetable-info' key={location.venue}>
                <div
                    className='title'
                    style={{
                        gridTemplateColumns: `repeat(${timeSlots.length}, var(--slot-width))`,
                        width: `calc(${timeSlots.length} * (var(--slot-width) + var(--slot-gap)))`,
                    }}
                >
                    <h3> {location.venue.toUpperCase()} </h3>
                </div>
                <div className='timetable' style={{gridTemplateColumns: generateTimeslotCss(timeSlots)}}>
                    {location.events.map((event: any) => {
                        return <TimetableEvent event={event} key={uuidv4()}/>
                    })}
                </div>
            </div>
        </div>
    );
}

export default TimetableRow;

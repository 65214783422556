import { ChangeEvent, useEffect, useState } from 'react';
import { RSVPInput } from '../RSVP/RSVPInput';
import { RSVPLabel } from '../RSVP/RSVPLabel';

interface Props {
	setGuestType: (guestType: 'dag' | 'avond') => void;
	setIsLoggedIn: (loggedIn: boolean) => void;
}

export const LoginScreen = ({ setGuestType, setIsLoggedIn }: Props) => {
	const [ticketCode, setTicketCode] = useState<string>('');

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		setTicketCode(e.target.value.toLowerCase());
	};

	const dagCode = 'fxh-0706';
	const avondCode = 'hxf-06av';

	useEffect(() => {
		if (ticketCode === dagCode || ticketCode === avondCode) {
			setIsLoggedIn(true);
			sessionStorage.setItem('loggedIn', 'true');

			if (ticketCode === dagCode) {
				setGuestType('dag');
				sessionStorage.setItem('guestType', 'dag');
			} else {
				setGuestType('avond');
				sessionStorage.setItem('guestType', 'avond');
			}
		}
	}, [ticketCode, setGuestType, setIsLoggedIn]);

	return (
		<div className='login-overlay'>
			<div className='login-wrapper'>
				<RSVPLabel
					title='Vul hier de code in die op je ticket staat, je vind deze naast de website link'
					id='login'
				>
					<RSVPInput
						type='text'
						value={ticketCode}
						id='login'
						placeHolder='code'
						onChangeHandler={handleInputChange}
					/>
				</RSVPLabel>
			</div>
		</div>
	);
};

import { ChangeEvent } from 'react';

interface RSVPInputProps {
	type: string;
	value: string;
	onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
	placeHolder: string;
	id: string;
	required?: boolean;
	name?: string;
}

export const RSVPInput = ({
	type,
	value,
	onChangeHandler,
	placeHolder,
	id,
	required = true,
	name
}: RSVPInputProps) => {
	return (
		<input
			className='form__input'
			type={type}
			value={value || ''}
			onChange={onChangeHandler}
			id={id}
			placeholder={placeHolder}
			required={required && required}
			name={name}
		/>
	);
};

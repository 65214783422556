export const generateTimeslotCss = (slots: string[]) => {
    let scssCode = '';

    slots.forEach((timeSlot) => {
        scssCode += `[t-${timeSlot}] var(--slot-width)`;
    });

    return scssCode;
};

export const formatTime = (timeString: string) => {
    const hour = timeString.substr(0, 2);
    const minute = timeString.substr(2);
    return `${hour}:${minute}`;
};

export const generateTimeSlots = (startTime: string, endTime: string, minutesPerTimeslot = 15) => {
  const slots = [];
  const startHour = parseInt(startTime.substr(0, 2), 10);
  const startMinute = parseInt(startTime.substr(2), 10);
  const endHour = parseInt(endTime.substr(0, 2), 10);
  const endMinute = parseInt(endTime.substr(2), 10);

  let currentHour = startHour;
  let currentMinute = startMinute;

  while (currentHour !== endHour || currentMinute !== endMinute) {
    const hour = String(currentHour).padStart(2, '0');
    const minute = String(currentMinute).padStart(2, '0');
    const timeSlot = hour + minute;
    slots.push(timeSlot);

    currentMinute += minutesPerTimeslot;
    if (currentMinute >= 60) {
      currentHour++;
      currentMinute = currentMinute % 60; // Adjust minute overflow
    }

    if (currentHour >= 24) {
      currentHour = 0;
    }
  }

  slots.push(endTime);
  return slots;
}
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';

interface FlipContainerProps {
    imgSrc: string;
    className: string;
    children: ReactNode;
}

const FlipContainer: FunctionComponent<FlipContainerProps> = ({imgSrc, className, children}) => {

    const [isActive, setIsActive] = useState(true);

    const randomNumber = Math.floor(Math.random() * (600)) + 300;

    useEffect(() => {
        setTimeout(() => {
            setIsActive(false)
        }, randomNumber)
    }, [])

    return (
        <div 
            className={`flip-container ${className} ${isActive ? 'hover' : ''}`} 
            onClick={() => setIsActive(!isActive)}
            >
            <div className="flipper">
                <div className="front">
                    {children}
                </div>
                <div className="back">
                    <img src={imgSrc} alt="" />
                </div>
            </div>
        </div>
    )
}

export default FlipContainer
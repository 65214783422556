import React, { ChangeEvent, useEffect, FunctionComponent } from 'react';
import { database } from '../../firebase';
import { ref, update, push, child } from 'firebase/database';
import { RSVPLabel } from './RSVPLabel';
import { RSVPInput } from './RSVPInput';
import { RSVPSelect } from './RSVPSelect';
import { RSVPTextarea } from './RSVPTextArea';
import { Button } from '../Button/Button';
import { FormData } from '../../pages/RSVP';
import { useGuestType } from '../../pages/Layout';

interface RSVPFormProps {
	submitted: boolean;
	setSubmitted: (submit: boolean) => void;
	isCamping: boolean;
	setIsCamping: (submit: boolean) => void;
	isComing: boolean;
	setIsComing: (submit: boolean) => void;
	formData: FormData;
	setFormData: (data: any) => void;
}

export const RSVPForm: FunctionComponent<RSVPFormProps> = ({submitted, setSubmitted, isComing, setIsComing, setIsCamping, isCamping, formData, setFormData}) => {
	const { guestType } = useGuestType();

	const handleInputChange = (
		e:
			| ChangeEvent<HTMLInputElement>
			| ChangeEvent<HTMLSelectElement>
			| ChangeEvent<HTMLTextAreaElement>
	) => {
		const { id, value } = e.target;
		setFormData((prevData: any) => ({ ...prevData, [id]: value }));
	};

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const updates: any = {};
		const newPostKey = push(child(ref(database), 'posts')).key;

		if (formData.name) {
			updates[formData.name + ' - ' + Math.floor(Math.random() * 30)] = formData;
		} else {
			updates['/' + newPostKey] = formData;
		}

		setSubmitted(true);
		update(ref(database), updates);
	};

	useEffect(() => {
		formData.presence === 'Nee' || !formData.presence
			? setIsComing(false)
			: setIsComing(true);
	}, [formData.presence]);

	useEffect(() => {
		!formData.sleep 
			? setIsCamping(false)
			: formData.sleep === 'Ja' 
				? setIsCamping(true)
				: setIsCamping(false);
	}, [formData.sleep]);

	if(submitted) {
		return null
	}

	return (

		<form className='form' onSubmit={handleSubmit}>
			<div className='form-body'>
				<RSVPLabel id='name' title='Wat is je naam?'>
					<RSVPInput
						type='text'
						value={formData.name || ''}
						id='name'
						placeHolder='Naam '
						onChangeHandler={handleInputChange}
					/>
				</RSVPLabel>
				<RSVPLabel id='email' title='Wat is je E-mailadres?'>
					<RSVPInput
						type='email'
						value={formData.email || ''}
						id='email'
						placeHolder='E-mailadres'
						onChangeHandler={handleInputChange}
					/>
				</RSVPLabel>

				<RSVPLabel id='presence' title='Ben je erbij?'>
					<RSVPSelect
						id='presence'
						onChangeHandler={handleInputChange}
						value={formData.presence || ''}
						selectOptions={['Ja, natuurlijk!', 'Nee']}
					/>
				</RSVPLabel>

				{isComing && (
					<>
						<RSVPLabel
							id='sleep'
							title='Wil je blijven kamperen?'
						>
							<RSVPSelect
								id='sleep'
								onChangeHandler={handleInputChange}
								value={formData.sleep || ''}
								selectOptions={[
									'Ja',
									'Nee, ik regel zelf een huisje',
									'Nee, ik slaap lekker thuis',
								]}
							/>
						{isCamping && <p>Een plekje op de camping kost €10,00 per persoon. Je reserveert je plekje in de volgende stap.</p>}
						</RSVPLabel>
						<RSVPLabel
							id='sleep'
							title='Ontbijt je mee in de ochtend?'
						>
							<RSVPSelect
								id='breakfast'
								onChangeHandler={handleInputChange}
								value={formData.breakfast || ''}
								selectOptions={[
									'Ja',
									'Nee',
								]}
							/>
						</RSVPLabel>
						{(guestType === 'dag' || formData.breakfast === 'Ja') &&
							<RSVPLabel
							id='diet'
							title='Heb je dieetwensen of allergieen?'
							>
								<RSVPTextarea
									value={formData.diet || ''}
									id='diet'
									placeHolder='Ik lust, wil of mag geen...'
									onChangeHandler={handleInputChange}
									/>
							</RSVPLabel>
						}
						<RSVPLabel
							id='requestsOverall'
							title='Zijn er verder nog dingen die je kwijt wilt?'
						>
							<RSVPTextarea
								value={formData.requestsOverall || ''}
								id='requestsOverall'
								placeHolder='Laat het ons weten!'
								onChangeHandler={handleInputChange}
							/>
						</RSVPLabel>
					</>
				)}
			</div>
			<div className='footer'>
				<Button buttonType='primary' type="submit" >
					Verstuur
				</Button>
			</div>
		</form>
	);
};

import ReactDOM from 'react-dom/client';
import { HashRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Faq from './pages/Faq';
import Programma from './pages/Programma';
import RSVP from './pages/RSVP';
import Info from './pages/Info';
import Tickets from './pages/Tickets';
import '../src/assets/styles/styles.scss'
import './index.scss';
import Contact from './pages/Contact';
import ScrollToTop from './components/ScrollToTop';

export default function App() {
	return (
		<HashRouter>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Home />} />
					<Route path='faq' element={<Faq />} />
					<Route path='rsvp' element={<RSVP />} />
					<Route path='timetable' element={<Programma isDirectTraffic />} />
					<Route path='timetable-dag' element={<Programma />} />
					<Route path='timetable-avond' element={<Programma />} />
					<Route path='info' element={<Info />} />
					<Route path='contact' element={<Contact />} />
					<Route path='tickets' element={<Tickets />} />
				</Route>
			</Routes>
			<ScrollToTop/>
		</HashRouter>
	);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<App />
);

import FaqItem from '../components/FaqItem/FaqItem';
import { useGuestType } from './Layout';


const Faq = () => {
	const { guestType } = useGuestType();

	return (
		<div className='faq-wrapper'>
			<h2>FAQ</h2>
			<div className="faq">
				<FaqItem>
					<h4>Wanneer en waar is de bruiloft?</h4>
					<p>De bruiloft vindt plaats bij Vakantiepark Eigen Wijze in Bant op 7 juni 2024. Je bent welkom vanaf 
						{guestType === 'dag' 
						? <span><strong> 15:30</strong>. De ceremonie begint om <strong>16:00</strong>, dus zorg dat je er optijd bent. We gaan er samen een mooie dag van maken!</span>
						: <span><strong>20:30</strong> voor het avondfeest. We gaan er samen een mooie avond van maken!</span>} 
					</p>
				</FaqItem>
				<FaqItem>
					<h4>Vanaf hoelaat kan ik mijn tentje opzetten?</h4>
					<p>
					Tentje opzetten kan op de dag zelf vanaf: {guestType === 'dag' ? '10:00' : 'avondtijd'}.
					Wanneer je een huisje hebt geboekt, is de check-in tijd in overleg met de locatie <br/>
					</p>
				</FaqItem>
				<FaqItem>
					<h4>Hoe kan ik mijn dieetwensen doorgeven?</h4>
					<p>Dieetwensen en/of allergieën kan je doorgeven bij het invullen van het <a href='/#/rsvp'>RSVP-formulier</a>. Wij zullen hier zeker rekening mee houden!</p>
				</FaqItem>
				<FaqItem>
					<h4>Kan ik parkeren op de locatie?</h4>
					<p>Gratis parkeergelegenheid is beschikbaar voor alle gasten op de locatie.</p>
				</FaqItem>
				<FaqItem>
					<h4>Is er ruimte voor een stukje of een speech?</h4>
					<p>{guestType === 'dag' ? 'Hoewel we niet staan te springen om dia-shows van 25 minuten, is er zeker ruimte om iets te doen mocht je dat graag willen. Dit is wel altijd in overleg met onze ceremoniemeesters.' : 'Nee'}</p>
				</FaqItem>
				<FaqItem>
					<h4>Wat kan ik geven als cadeau?</h4>
					<p>Jullie aanwezigheid is voor ons al een fantastisch cadeau! Maar, als jullie iets willen geven, zouden wij een bijdrage voor onze huwelijksreis erg waarderen.</p>
				</FaqItem>
				<FaqItem>
					<h4>Hoe kan ik doorgeven dat ik erbij ben?</h4>
					<p>Bevestig je aanwezigheid via het <a href='/#/rsvp'>RSVP-formulier</a>. Ook als je niet aanwezig kunt zijn, is het fijn als je het formulier invult. Zo weten wij op tijd hoeveel mensen er aanwezig zullen zijn.</p>
				</FaqItem>
				<FaqItem>
					<h4>Kan ik blijven slapen?</h4>
					<p>Jazeker, de camping is open voor iedereen. Kamperen kost 10 euro per persoon en je kunt je tentje opzetten vanaf 10:00 op de dag zelf.  </p>
				</FaqItem>
				<FaqItem>
					<h4>Staat je vraag er niet tussen?</h4>
					<p>Neem dan contact op met onze lieve ceremoniemeesters Carlien en Pim, of stuur ons een berichtje. Meer informatie vind je op de <a href='/#/contact'>contact pagina</a></p>
				</FaqItem>
			</div>
		</div>
	);
};

export default Faq;

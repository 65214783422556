import { ContactPerson } from '../components/ContactPerson/ContactPerson';
import carlien from '../assets/images/carlien.jpeg';
import pim from '../assets/images/pim.jpeg';

const Contact = () => {
    return (
        <div>
            <h2>Contact</h2>
            <div className="contact-person__wrapper">
                <ContactPerson
                    name='Carlien'
                    titel='Ceremoniemeester'
                    number='0629443650'
                    imageUrl={carlien}
                />
                <ContactPerson
                    name='Pim'
                    titel='Ceremoniemeester'
                    number='0682532864'
                    imageUrl={pim}
                />
            </div>
        </div>
    )
}

export default Contact;
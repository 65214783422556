import { useEffect, useState } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { LoginScreen } from '../components/LoginScreen/LoginScreen';
import { Nav } from '../components/Nav/Nav';
import ScrollToTop from '../components/ScrollToTop';

type guestTypes = {
	guestType: 'dag' | 'avond';
	setGuestType: (guestType: 'dag' | 'avond') => void;
};
type loggedInTypes = {
	isLoggedIn: boolean;
	setIsLoggedIn: (isLoggedIn: boolean) => void;
};

const Layout = () => {
	const [guestType, setGuestType] = useState<'dag' | 'avond'>();
	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
	const [isDirectTraffic, setIsDirectTraffic] = useState<boolean>(false);

	const handlePreviouslyLoggedIn = () => {
		const loggedInData = sessionStorage.getItem('loggedIn') === 'true';
		const guestTypeData = sessionStorage.getItem('guestType');
		loggedInData && setIsLoggedIn(true);
		(guestTypeData === 'dag' || guestTypeData === 'avond') &&
			setGuestType(guestTypeData);
	};

	useEffect(() => {
		handlePreviouslyLoggedIn();
		if(window.location.hash === '#/timetable') {
			setIsDirectTraffic(true)
			setIsLoggedIn(true)
			setGuestType('dag')
		}
	}, []);

	return (
		<>
			<Nav isDirectTraffic={isDirectTraffic} isLoggedIn={isLoggedIn} guestType={guestType}/>
			<div className='layout'>
				{!isLoggedIn && !isDirectTraffic ? (
					<LoginScreen
						setIsLoggedIn={setIsLoggedIn}
						setGuestType={setGuestType}
					/>
				) : (
					<>					
						<ScrollToTop/>
						<Outlet
							context={{
								guestType,
								isLoggedIn,
							}}
						/>
					</>
				)}
				<div className='trademark'>@handmade by Hannet</div>
			</div>
		</>
	);
};

export default Layout;

export function useGuestType() {
	return useOutletContext<guestTypes>();
}

export function useLoggedInState() {
	return useOutletContext<loggedInTypes>();
}

import { ChangeEvent } from 'react';

interface RSVPTextareaProps {
	value: string;
	onChangeHandler: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	placeHolder: string;
	id: string;
}

export const RSVPTextarea = ({
	value,
	onChangeHandler,
	placeHolder,
	id,
}: RSVPTextareaProps) => {
	return (
		<textarea
			className='form__input'
			value={value || ''}
			onChange={onChangeHandler}
			id={id}
			placeholder={placeHolder}
		/>
	);
};

import { useState, useEffect, FunctionComponent } from 'react';

interface ImageSliderProps {
  images: string[];
}

const ImageSlider: FunctionComponent<ImageSliderProps> = ({images}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); 

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="image-wrapper slider">
        {images.map((image, index) => (
            <div key={index} className={`img-container ${currentImageIndex === index ? 'active' : ''}`}>
                <img src={image} alt="" />
            </div>
        ))}
    </div>
  );
};

export default ImageSlider;
import { FunctionComponent } from 'react';
import { formatTime } from './TimetableHandlers';
import { v4 as uuidv4 } from 'uuid';

interface TimetableEventProps {
    event: any;
}

const TimetableEvent: FunctionComponent<TimetableEventProps> = ({event}) => {
    const isAfterparty = event.event === 'After party op de camping?';
    const eventTimes = !isAfterparty
        ? `${formatTime(
                event.startTime
        )} - ${formatTime(event.endTime)}`
        : `${formatTime(event.startTime)} - ${
                event.endTime
        }`;
        
    const eventClassName = `timetable-event ${
        isAfterparty ? 'afterparty' : ''
    }`;

    return (
        <div
            className={eventClassName}
            style={{
                gridColumn: `t-${event.startTime} / t-${event.endTime}`,
            }}
            data-times={eventTimes}
            key={uuidv4()}
        >
            {event.event}
        </div>
    );
}

export default TimetableEvent;

import { FunctionComponent, useState } from 'react';
import { Button } from '../Button/Button';

interface navProps {
    isDirectTraffic?: boolean;
    isLoggedIn?: boolean;
    guestType?: 'dag' | 'avond'
}

export const Nav: FunctionComponent<navProps> = ({isDirectTraffic, isLoggedIn, guestType}) => {
    const [hamburgerActive, setHamburgerActive] = useState(false);

    const toggleHamburgerMenu = () => {
        setHamburgerActive(!hamburgerActive);
    };

    const timetableUrl = () => {
        if(isLoggedIn && guestType === 'dag') {
            return '/#/timetable-dag'
        } else if (isLoggedIn && guestType === 'avond') {
            return '/#/timetable-avond'
        }
        return '/#/timetable'
    }

    return (
        <header>
            <div id="menu">
                <nav className="topnav">
					<a href="/" className='logo'>
						<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" zoomAndPan="magnify" viewBox="0 0 224.87999 198.749996" preserveAspectRatio="xMidYMid meet" version="1.0"><defs><clipPath id="c5de077f81"><path d="M 0.0820312 0 L 224.679688 0 L 224.679688 198.5 L 0.0820312 198.5 Z M 0.0820312 0 " clipRule="nonzero"/></clipPath></defs><g clipPath="url(#c5de077f81)"><path d="M 96.1875 -0.0117188 L 92.085938 3.269531 L 109.3125 29.667969 L 0.804688 198.808594 L 81.496094 198.808594 L 81.496094 142.429688 L 143.839844 142.429688 L 143.839844 198.808594 L 224.535156 198.808594 L 116.023438 29.59375 L 132.28125 3.566406 L 128.253906 0.285156 L 112.667969 25.492188 L 96.1875 -0.015625 Z M 96.1875 -0.0117188 " fillOpacity="1" fillRule="evenodd"/></g></svg>
					</a>
                    <div className="navlinks">
                        <Button buttonType='link' buttonUrl='/#/info'>Belangrijke info</Button>
                        <Button buttonType='link' buttonUrl='/#/rsvp'>RSVP</Button>
                        <Button buttonType='link' buttonUrl='/#/tickets'>Camping tickets</Button>
                        <Button buttonType='link' buttonUrl={timetableUrl()}>Timetable</Button>
                        <Button buttonType='link' buttonUrl='/#/contact'>Contact</Button>

                    </div>
                </nav>
            </div>
            <div id="mobile-menu" className={ hamburgerActive ? 'is-active' : ''}>
                <div className="menu-toggle">
                    <div className="mobilemenu-icon" onClick={toggleHamburgerMenu} />
                    <span></span>
                    <span></span>
                    <span></span>
                    <div className="mobilemenu-items">
						<Button buttonType='link' buttonUrl='/#/info' onClick={toggleHamburgerMenu}>Belangrijke info</Button>
                        <Button buttonType='link' buttonUrl='/#/rsvp' onClick={toggleHamburgerMenu}>RSVP</Button>
                        <Button buttonType='link' buttonUrl={timetableUrl()} onClick={toggleHamburgerMenu}>Timetable</Button>
                        <Button buttonType='link' buttonUrl='/#/contact' onClick={toggleHamburgerMenu}>Contact</Button>
                        <Button buttonType='primary' buttonUrl='/#/tickets' onClick={toggleHamburgerMenu}>Camping tickets</Button>
                    </div>
                </div>
            </div>
        </header>
    );
};

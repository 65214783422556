// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyCT9eAaTkW_Qih4k9t37oU16ttWOrqMQW0",
	authDomain: "test-2c4dd.firebaseapp.com",
	databaseURL: "https://test-2c4dd-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "test-2c4dd",
	storageBucket: "test-2c4dd.appspot.com",
	messagingSenderId: "409347143563",
	appId: "1:409347143563:web:fe6099e43c4a318c95618e"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);

import { useEffect, useRef, useState } from 'react';
import { formatTime } from './TimetableHandlers';
import TimetableRow from './TimetableRow';
import { useGuestType } from '../../pages/Layout';
import { v4 as uuidv4 } from 'uuid';

interface TimetableProps {
	eventLayout: any[];
	timeSlots: string[];
	eventDay: string;
}

const Timetable: React.FC<TimetableProps> = ({ eventLayout, timeSlots, eventDay }) => {
	const {guestType} = useGuestType();

	const [timeSlotsWidth, setTimeSlotsWidth] = useState<number>(0);
	const [percentage, setPercentage] = useState<number>(0);

	const timetableRef = useRef<HTMLDivElement>(null);
	const timeslotsRef = useRef<HTMLDivElement>(null);
	const timelineRef = useRef<HTMLDivElement>(null);

	const starttimeFriday = guestType === 'dag' ? 15 : 20;
	const isWeddingDay = eventDay === 'Friday' 
		? new Date().getDate() === 7 && new Date().getMonth() === 5
		: new Date().getDate() === 8 && new Date().getMonth() === 5

	useEffect(() => {
		if (timeslotsRef.current) {
			setTimeSlotsWidth(timeslotsRef.current.scrollWidth);
		}
	}, [timeslotsRef, timeSlots]);

	useEffect(() => {
		// time of day 
		const currentTime = new Date();
		const currentTimeInMinutes = currentTime.getHours() * 60 + currentTime.getMinutes();

		// event time
		const startTimeInMinutes = eventDay === 'Friday' ? starttimeFriday * 60 : 8 * 60;
		const endTimeEventInMinutes = eventDay === 'Friday' ? 25 * 60 : 12 * 60;

		// check if current time is in event 
		if (currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes <= endTimeEventInMinutes) {
			const minutesPassed = currentTimeInMinutes - startTimeInMinutes;
			const totalTimeSpan = endTimeEventInMinutes - startTimeInMinutes;
			const percentageOfTimePassed = (minutesPassed / totalTimeSpan);
			
			// set percentage to percentageOfTimePassed and adjust with gapwidth correction
			setPercentage((percentageOfTimePassed * timeSlotsWidth) + (8 * percentageOfTimePassed));
		} else {
			setPercentage(0); // Return false if the current time is not within the timetable range
		}
	}, [timeSlotsWidth, timeSlots, eventDay, starttimeFriday]);

	useEffect(() => {
		const scrollToPercentage = () => {
			if (timetableRef.current && percentage > 0 && isWeddingDay) {
				timetableRef.current.scrollTo({
					left: percentage - 30,
					behavior: 'smooth' // Add smooth scroll behavior
				});
			}
		};
		const scrollTimeout = setTimeout(scrollToPercentage, 500);
		return () => clearTimeout(scrollTimeout);
	}, [timetableRef, percentage, timeSlots, isWeddingDay]);

	return (
		<div className='timetable-wrapper' ref={timetableRef}>
			{percentage !== 0 && isWeddingDay && <div className='timeline' style={{ left: `${percentage}px` }} ref={timelineRef} />}
			<div
				className='timetable-header'
				style={{
					gridTemplateColumns: `repeat(${timeSlots.length}, var(--slot-width))`,
				}}
				ref={timeslotsRef}
			>
				{timeSlots.map((slot) => (
					<div className='timetable-header-slot' key={uuidv4()}>
						{formatTime(slot)}
					</div>
				))}
			</div>
			{eventLayout.map((location) => (
				<TimetableRow key={uuidv4()} location={location} timeSlots={timeSlots} />
			))}
		</div>
	);
};

export default Timetable;

import { ChangeEvent } from 'react';

interface RSVPSelectProps {
	value: string;
	onChangeHandler: (
		e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>
	) => void;
	id: string;
	selectOptions: string[];
	required?: boolean;
}

export const RSVPSelect = ({
	value,
	onChangeHandler,
	id,
	selectOptions,
	required = true,
}: RSVPSelectProps) => {
	return (
		<select
			name={id}
			id={id}
			onChange={onChangeHandler}
			value={value}
			required={required && required}
		>
			<option selected disabled value=''>
				-- kies een optie --
			</option>
			{selectOptions.map((selectOption, index) => (
				<option key={selectOption} value={selectOptions[index]}>
					{selectOption}
				</option>
			))}
		</select>
	);
};

//https://agib.dk/posts/2019/pan-and-scroll-schedule-demo.html
import { FunctionComponent, useState } from 'react';
import Timetable  from '../components/Timetable/Timetable';
import {eventLayoutFriday, timeSlotsFriday, eventLayoutSaturday, timeSlotsSaturday, eventLayoutFridayEvening, timeSlotsFridayEvening} from '../components/Timetable/TimetableInput';
import { Button } from '../components/Button/Button';
import { useGuestType } from './Layout';

interface programmaProps {
	isDirectTraffic?: boolean;
}

const Programma: FunctionComponent<programmaProps> = ({isDirectTraffic}) => {
	const { guestType } = useGuestType();
	const guestTypeUsed = isDirectTraffic ? 'dag' : guestType

	const [eventDay, setEventDay] = useState('Friday');
	
	const fridayEvents = guestTypeUsed === 'dag' ? eventLayoutFriday : eventLayoutFridayEvening
	const fridaySlots = guestTypeUsed === 'dag' ? timeSlotsFriday : timeSlotsFridayEvening

	const handleClick = (day: string) => {
		setEventDay(day)
	}

	return (
		<>
			<h2 className='nomp'>Timetable</h2>
			{ !isDirectTraffic && (
				<p>Bekijk de timetable om te weten waar en wanneer je overal moet zijn. 
				
					{guestTypeUsed === 'dag' && ' Alle locaties bevinden zich bij Eigen Wijze, dus alles is op loopafstand. Ook wordt er gedurende de dag duidelijk aangegeven waar je naartoe moet.'}
	
				</p>
			)}
			<div className="button-wrapper">
				<Button buttonType="primary" onClick={() => handleClick('Friday')} className={eventDay === 'Friday' ? 'active' : ''}>Friday</Button>
				<Button buttonType="primary" onClick={() => handleClick('Saturday')} className={eventDay === 'Saturday' ? 'active' : ''}>Saturday</Button>
			</div>
			<Timetable 
				eventLayout={eventDay === 'Friday' ? fridayEvents : eventLayoutSaturday} 
				timeSlots={eventDay === 'Friday' ? fridaySlots : timeSlotsSaturday} 
				eventDay={eventDay}
			/>
		</>
	);
};

export default Programma;
